<template>
  <div class="header-top-navbar">
    <b-container>
      <div class="header-top-navbar-wrap">
        <div class="d-flex align-items-center">
          <b-navbar class="custom-nav-bar navbar navbar-expand-lg navbar-light">
            <div class="header-top-search form-inline">
              <label>
                <b-form @submit.prevent autocomplete="off">
                  <input
                    v-model="searchText"
                    class="header-top-search-input"
                    type="search"
                    id="input"
                    @keyup.enter="submit"
                    :placeholder="$t('search_placeholder')"
                    @focus="showPopup"
                  />
                </b-form>
              </label>

              <b-link
                @click="clearText"
                class="search-icon"
                v-if="this.searchText.length"
              >
                <font-awesome-icon icon="times" style="color: #666" />
              </b-link>

              <b-link class="search-icon" @click.stop.prevent="submit()" v-else>
                <font-awesome-icon icon="search" style="color: #666" />
              </b-link>

              <SearchComponent
                @handleSearch="handleSearch"
                :recommendedProducts="recommendedProducts"
                :recommendedCategories="recommendedCategories"
                :recommendedBlogs="recommendedBlogs"
                :searchSuggestions="searchSuggestions"
                :searchText="searchText"
              />
            </div>
          </b-navbar>
          <InformativeLine />
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import SearchComponent from "@/esf_utrecht_bankxl/core/components/header/SearchComponent.vue";
import InformativeLine from "@/esf_utrecht_bankxl/core/components/core/InformativeLine";
import { getProductsBySearchPreview } from "@storefront/core/data-resolver/products";

export default {
  name: "HeaderSearch",
  props: {
    msg: String,
  },
  components: { SearchComponent, InformativeLine },
  data: () => ({
    searchText: "",
    searchlTimer: null,
    searchSuggestions: [],
    recommendedProducts: [],
    recommendedCategories: [],
    recommendedBlogs: [],
    closeSignal: {
      products: null,
      categories: null,
      blogs: null,
    },
  }),
  methods: {
    showPopup() {
      if (this.searchText.length > 0) {
        this.$root.$emit("showSearchModal");
      }
    },
    closesPopup() {
      this.$root.$emit("closeSearchModal");
    },
    handleSearch(searchVal = "") {
      this.searchText = searchVal;
    },
    submit() {
      //if you want to send any data into server before redirection then you can do it here
      if (this.searchText != "") {
        clearTimeout(this.searchlTimer);
        this.abortAllSignals();
        this.closesPopup();
        this.$router.push("/search/?q=" + this.searchText);
        this.searchText = "";
      }
    },
    clearText() {
      this.searchText = "";
    },
    async getSearchPreview() {
      const controller = new AbortController();
      const { signal } = controller;

      this.closeSignal.products = controller;
      const output = await getProductsBySearchPreview(
        this.searchText,
        signal,
        20
      );
      this.closeSignal.products = null;
      this.recommendedProducts = output.items;
      this.searchSuggestions = output.search_suggestions;
    },
    async getSearchPreviewCategories(searchText) {
      const controller = new AbortController();
      const { signal } = controller;

      this.closeSignal.categories = controller;
      const output = await this.$store.dispatch(
        "productSerie/getCategoriesBySearchPreview",
        { filter: searchText, signal }
      );

      this.closeSignal.categories = null;
      // output.items.forEach((item, index, object) => {
      //   if (item.level == 2) {
      //     object.splice(index, 1);
      //   }
      // });
      this.recommendedCategories = output.items;
    },
    async getSearchPreviewBlogs(searchText) {
      const controller = new AbortController();
      const { signal } = controller;

      this.closeSignal.blogs = controller;
      const output = await this.$store.dispatch(
        "productSerie/getBlogsBySearchPreview",
        { filter: searchText, signal }
      );
      this.closeSignal.blogs = null;
      this.recommendedBlogs = output.items;
    },
    abortAllSignals() {
      if (this.closeSignal.products !== null) {
        this.closeSignal.products.abort();
      }

      if (this.closeSignal.categories !== null) {
        this.closeSignal.categories.abort();
      }
      if (this.closeSignal.blogs !== null) {
        this.closeSignal.blogs.abort();
      }
    },
  },
  watch: {
    searchText() {
      if (this.searchText.length > 2) {
        clearTimeout(this.searchlTimer);

        this.searchlTimer = setTimeout(() => {
          this.abortAllSignals();

          this.showPopup();
          this.getSearchPreview();
          this.getSearchPreviewCategories(this.searchText);
          this.getSearchPreviewBlogs(this.searchText);
        }, 300);
      } else {
        this.closesPopup();
      }
    },
  },
};
</script>
