<template>
  <div class="header-top-megamenu-wrap">
    <div class="custom-mega-menu">
      <ul class="top-megamenu-list">
        <ClientOnly>
          <b-nav-item class="top-megamenu-icon" to="/">
            <font-awesome-icon icon="home" />
          </b-nav-item>
          <li
            v-for="(item, index) in menu"
            :key="`menu-` + index"
            class="nav-item"
          >
            <b-link class="nav-link" :to="`/` + item.url_path + '/'">{{
              item.name
            }}</b-link>
          </li>
          <b-nav-item class="top-megamenu-icon" to="/merken/">
            Merken
          </b-nav-item>
          <StoreSwitcher />
        </ClientOnly>
      </ul>
    </div>
  </div>
</template>
<script>
import homingLogo from "@/esf_utrecht_bankxl/assets/images/homig-xl.png";
import ClientOnly from "vue-client-only";
// import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";
import StoreSwitcher from "@/esf_utrecht_bankxl/core/components/header/StoreSwitcher";

export default {
  name: "HeaderTopMenu",
  components: { ClientOnly, /*cmsBlockSimple,*/ StoreSwitcher },
  data() {
    return {
      homingLogo,
      icon: "chevron-down",
    };
  },
  computed: {
    menu() {
      return this.$store.getters["menu/getMenu"].slice(0, 6);
    },
    storeSwitch() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "header_store_switch"
      );
    },
  },
  methods: {
    showDropDown() {
      const el = document.getElementById("dropDown");
      const classes = el.classList.value;
      if (!classes.includes("d-block")) {
        this.icon = "chevron-up";
        el.classList.add("d-block");
      } else {
        this.icon = "chevron-down";
        el.classList.remove("d-block");
      }
    },
    hideDropDown() {
      const el = document.getElementById("dropDown");
      const classes = el.classList.value;
      if (classes.includes("d-block")) {
        this.icon = "chevron-down";
        el.classList.remove("d-block");
      }
    },
  },
};
</script>
